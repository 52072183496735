<template>
  <div class="report-list">
    <div class="report-item" v-for="(item, index) in reportList" :key="index">
      <div class="report-main">
        <img class="report-img" src="@/assets/report-img.png" alt="" />
        <div>
          <!-- <div class="product-name">套餐名称：{{ item.productName }}</div> -->
          <div class="user-name">
            <span style="color: #878d99">体检人：</span>
            <span style="color: #878d99">{{ item.displayName }}</span>
          </div>
          <div class="appointment-date">
            {{ item.appointmentDate }}
          </div>
        </div>
      </div>
      <div class="button-container">
        <a style="margin-right: 10px" @click="onPreview(item)">
          <van-button class="button" type="default" size="mini" round
            >预览报告</van-button
          >
        </a>
        <a @click="openReport(item)">
          <van-button class="button" type="primary" size="mini" round
            >下载报告</van-button
          >
        </a>
      </div>
    </div>
    <van-empty description="暂无数据" v-if="!reportList.length" />
    <van-popup class="popup" v-model="showDialog" round @opened="opened">
      <div class="popup-header">验证体检人姓名</div>
      <div class="popup-desc">请输入完整的姓名</div>
      <input ref="input" v-model="inputName" class="input" type="text" />
      <van-button class="popup-button" type="primary" round @click="confirm"
        >验证</van-button
      >
      <div class="popup-cancel" @click="showDialog = false">取消</div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";

// import pdf from 'vue-pdf'
import { GetReportList, validateReportName } from "@/api";
import { courseDownload } from "@/utils/download.js";
import { judgeClient } from "@/utils/index.js";
export default {
  name: "report-list",
  components: {},
  data() {
    return {
      reportList: [],
      current: {},
      loadingTask: "",
      isLoading: false,
      showDialog: false,
      downloadUrl: "",
      inputName: "",
      downloadName: "",
      isPreview: false,
      reportId: "",
    };
  },
  computed: {
    userToken() {
      return this.$store.state.token;
    },
  },
  watch: {
    userToken: {
      handler(newValue) {
        console.log(newValue, "token");
        if (newValue) {
          this.getReportList();
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.$route.query.hospitalId) {
      this.$store.commit("setHospitalId", this.$route.query.hospitalId);
      localStorage.setItem("hospitalId", this.$route.query.hospitalId);
    }
  },
  methods: {
    async getReportList() {
      let data = await GetReportList();
      const list = data.map((item) => {
        return {
          ...item,
          displayName: item.appointmentMemberName,
        };
      });
      this.reportList = list;
    },
    onPreview(item) {
      this.isPreview = true;
      this.reportId = item.reportId;
      this.showDialog = true;
    },
    openReport(item) {
      this.isPreview = false;
      this.reportId = item.reportId;
      this.showDialog = true;
    },
    opened() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    async confirm() {
      await this.validateName();
      this.showDialog = false;
      this.inputName = "";
      if (this.isPreview) {
        this.previewReport();
      } else {
        this.downloadReport();
      }
    },
    validateName() {
      return new Promise(async (resolve, reject) => {
        if (!this.inputName) {
          this.$toast("请输入姓名！");
          return reject();
        }
        const res = await validateReportName({
          reportId: this.reportId,
          appointmentMemberName: this.inputName.trim(),
        }).catch(() => {
          this.inputName = "";
        });
        this.inputName = "";
        if (res && res.isSuccess) {
          this.downloadUrl = res.reportUrl;
          resolve(res.reportUrl);
        } else {
          this.$toast(res.errorMessage);
          return reject();
        }
      });
    },
    previewReport() {
      window.location.href = `${
        process.env.VUE_APP_BASEURL
      }/pdf/web/viewer.html?file=${encodeURIComponent(this.downloadUrl)}`;
    },
    downloadReport() {
      fetch("https://img.glztj.com/api/ApplyImageAccessToken")
        .then((response) => {
          response
            .json()
            .then((data) => {
              console.log(data);
              const downloadUrl = this.downloadUrl + "&auth=" + data.data;
              if (judgeClient() === "Android") {
                if (this.downloadUrl.includes("GetThirdReportDetail")) {
                  Toast.loading({
                    duration: 6000,
                    overlay: true,
                    loadingType: "spinner",
                  });
                }
                location.href = downloadUrl;
              } else {
                location.href = downloadUrl;
                return
                courseDownload(downloadUrl, "检查报告.pdf");
              }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style>
html {
  background-color: #f4f4f4;
}
</style>

<style lang="scss" scoped>
.report-list {
  padding: 20px 10px;
  background-color: #f4f4f4;
}

.report-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.report-main {
  display: flex;
}

.report-img {
  margin-right: 12px;
  width: 60px;
  height: 60px;
}

.product-name {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}

.user-name {
  margin-top: 5px;
  color: #333333;
  font-size: 15px;
}

.appointment-date {
  margin-top: 5px;
  font-size: 15px;
  color: #878d99;
}

.button-container {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button {
  height: 30px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: bold;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.popup-header {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
}

.popup-desc {
  margin-top: 10px;
  font-size: 14px;
  color: #878d99;
}

.input {
  margin-top: 20px;
  width: 250px;
  padding: 0 10px;
  height: 35px;
  border-radius: 10px;
  font-size: 15px;
  color: #2c2f37;
  background-color: #edeff0;
}

.popup-button {
  margin-top: 30px;
  width: 150px;
  height: 40px;
  font-size: 16px;
}

.popup-cancel {
  margin: 10px 0 20px 0;
  font-size: 14px;
  color: #878d99;
}
</style>
